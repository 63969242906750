export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47')
];

export const server_loads = [0,5,6,4,7];

export const dictionary = {
		"/": [~8],
		"/barang": [~9],
		"/barang/[item]": [~10],
		"/barang/[item]/negeri/[negeri]": [~11],
		"/barang/[item]/negeri/[negeri]/[daerah]": [~12],
		"/kategori/[group]": [~13],
		"/kategori/[group]/[category]": [~14],
		"/kategori/[group]/[category]/[subcategory]": [~15],
		"/kedai2": [~22],
		"/kedai": [~16,[2]],
		"/kedai/[premise]": [~17,[2]],
		"/kedai/[premise]/barang/[item]": [~18,[2]],
		"/kedai/[premise]/kategori/[group]": [~19,[2]],
		"/kedai/[premise]/kategori/[group]/[category]": [~20,[2]],
		"/kedai/[premise]/kategori/[group]/[category]/[subcategory]": [~21,[2]],
		"/my": [23,[3]],
		"/my/about": [25,[3]],
		"/my/category": [26,[3,5]],
		"/my/category/health-beauty": [27,[3,5,6]],
		"/my/contact-us": [28,[3]],
		"/my/cpra": [29,[3]],
		"/my/disclaimer": [30,[3]],
		"/my/gdpr": [31,[3]],
		"/my/pdpa": [32,[3]],
		"/my/refund": [33,[3]],
		"/my/terms": [34,[3]],
		"/my/[others]": [24,[3,4]],
		"/negeri/[negeri]": [~35],
		"/negeri/[negeri]/daerah/[daerah]": [~36],
		"/negeri/[negeri]/daerah/[daerah]/kategori/[group]": [~37],
		"/negeri/[negeri]/daerah/[daerah]/kategori/[group]/[category]": [~38],
		"/negeri/[negeri]/daerah/[daerah]/kategori/[group]/[category]/[subcategory]": [~39],
		"/negeri/[negeri]/kategori/[group]": [~40],
		"/negeri/[negeri]/kategori/[group]/[category]": [~41],
		"/negeri/[negeri]/kategori/[group]/[category]/[subcategory]": [~42],
		"/rangkaian": [43],
		"/rangkaian/[chain]": [44],
		"/rangkaian/[chain]/negeri/[negeri]": [45],
		"/rangkaian/[chain]/negeri/[negeri]/daerah/[daerah]": [46],
		"/sitemap": [47,[7]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';